import { Row, Col, Tabs, Table, Modal, message, Popconfirm, Radio, Spin, Checkbox, Tooltip } from "antd";
import "./style.scss";
import "./responsive.scss";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { Button, Input, AutoComplete } from "antd";
import { Space, Tag, Form } from "antd";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import QRCode from "qrcode.react";
import promptpayQR from "promptpay-qr";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { getDoc, doc, Timestamp, updateDoc, addDoc, collection, setDoc, getDocs } from "firebase/firestore";
import googleSignInIcon from "../../assets/icons/google-signin-icon.png";
import { useLocation } from "react-router-dom";

import {
  UsergroupAddOutlined,
  QrcodeOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  UserOutlined,
  EyeOutlined,
  SaveOutlined,
  ShareAltOutlined,
  ClearOutlined,
  UnorderedListOutlined,
  InfoCircleOutlined,
  CalculatorOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import GoogleAuthen from "./GoogleAuthen";
import { db } from "../../firebase/firebase_db";
import { auth, loginWithGoogle } from "../../firebase/auth";
import useUserStore from "./userStore";
import { useTranslation } from "react-i18next";
import { suggestions } from "../../utils/suggestionMenu";
import Calculator from "../Calculator/Calculator";
import UploadBill from "./components/UploadBill";

const { CheckableTag } = Tag;

export interface Person {
  id: number;
  peopleName: string;
  price: string;
  color: string;
  backgroundColor: string;
  paymentStatus: boolean;
}

export interface Menu {
  key: number;
  listName: string;
  price: string;
  each: string;
  selectedPeople: string[];
}

interface QRPromptPay {
  phoneNumber: string;
  amount: number;
}

export interface BillEach {
  key: any;
  userId?: string;
  dataPeople?: Person[];
  dataMenu?: Menu[];
  timestamp?: Timestamp;
  billName?: string;
  phoneNumber?: string;
}

function isInWebView() {
  const ua = navigator.userAgent;
  return /FB_IAB|FBAN|Instagram|Line|Twitter/.test(ua) || ua.includes("Instagram");
}

export default function Bill() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const location = useLocation();
  const [formAddPeople] = Form.useForm();
  const [formMenu] = Form.useForm();
  const [formInputMenuName] = Form.useForm();
  const [formEditName] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listNameInModal, setListNameInModal] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [autoFocusQRCode, setAutoFocusQRCode] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phoneNumber") || "");
  const [billName, setBillName] = useState(localStorage.getItem("billName") || "");
  const [showInputBillName, setShowInputBillName] = useState(false);
  const [autoFocusBillName, setAutoFocusBillName] = useState(false);
  const [showQRCode, setShowQRCode] = useState(Boolean(localStorage.getItem("phoneNumber")));
  const [personToEdit, setPersonToEdit] = useState<Person | null>(null);
  const [editingName, setEditingName] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [editingItem, setEditingItem] = useState<Menu | null>(null);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");
  const [hideElement, setHideElement] = useState(false);
  const [hideAuthenMenu, setHideAuthenMenu] = useState(false);
  const [localPrice, setLocalPrice] = useState(0);
  const [isShowModalConfirmDelete, setIsShowModalConfirmDelete] = useState(false);
  const [typeofDelete, setTypeofDelete] = useState("");
  const [isViewOrderModalOpen, setViewOrderModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);
  const inputRef = useRef<any>(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [spinning, setSpinning] = useState(false);
  const [fetchedBillData, setFetchedBillData] = useState(false);
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [LoadingShareButton, setLoadingShareButton] = useState(false);
  const { billId, billIdEdit } = useParams<{ billId?: string; billIdEdit?: string }>();
  const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
  const isEditMode = !!billIdEdit;
  const { userDetail } = useUserStore();
  const [lastAction, setLastAction] = useState<"add" | "delete" | null>(null);
  const isInsideWebView = isInWebView();
  const [modalWarningWebView, setModalWarningWebView] = useState(false);
  const [modalClearBillOpen, setModalClearBillOpen] = useState(false);
  const [isOwner, setIsOwner] = useState<boolean | null>(null);
  const [errorNeedBillName, setErrorNeedBillName] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState(suggestions);
  const [openDropdownSuggestion, setOpenDropdownSuggestion] = useState(false);
  const [modalCalculatorOpen, setModalCalculatorOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const [editByOther, setEditByOther] = useState(false);

  const handleSearch = (searchText: string) => {
    if (searchText.length < 3) {
      setFilteredSuggestions([]);
      setOpenDropdownSuggestion(false);
      return;
    }

    const filtered = suggestions.filter((suggestion) => suggestion.includes(searchText));
    setFilteredSuggestions(filtered);

    setOpenDropdownSuggestion(filtered.length > 0);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        checkOwnership(billIdEdit as string);
      } else {
        setIsOwner(false);
      }
    });
    return () => unsubscribe();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billIdEdit]);

  const checkOwnership = async (billId: string) => {
    // Check if user is on the main page
    if (location.pathname === "/") {
      setIsOwner(true);
      return;
    }

    if (!billId) {
      console.error("billId is undefined!");
      setIsOwner(false);
      return;
    }

    const currentUserID = auth.currentUser?.uid;
    if (!currentUserID) {
      console.error("No user logged in!");
      setIsOwner(false);
      return;
    }

    // Fetch the userBills sub-collection for the currently logged-in user
    const userBillsRef = collection(db, "users", currentUserID, "userBills");
    const userBillsSnap = await getDocs(userBillsRef);

    // Extract all the billId values
    const ownedBills = userBillsSnap.docs.map((doc) => doc.data().billId);

    const isOwnerOfBill = ownedBills.includes(billId);

    setIsOwner(isOwnerOfBill);
  };

  useEffect(() => {
    if (isInsideWebView && !window.location.pathname.includes("/view-bill")) {
      setModalWarningWebView(true);
    }
  }, [isInsideWebView]);

  useEffect(() => {
    if (userDetail) {
      setIsModalLoginOpen(false);
    }
  }, [userDetail]);

  const userExists = async (userId: string) => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    return userSnapshot.exists();
  };

  const upsertUser = async (userDetail: any) => {
    const { uid, email, displayName, photoURL, phoneNumber } = userDetail;

    const userRef = doc(db, "users", uid);

    if (await userExists(uid)) {
      await updateDoc(userRef, {
        email,
        displayName,
        photoURL,
        phoneNumber,
      });
    } else {
      await setDoc(userRef, {
        uid,
        email,
        displayName,
        photoURL,
        phoneNumber,
      });
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      message.success(t("successMessage.copyUrlComplete"));
    } catch (error) {
      message.error("ไม่สามารถแชร์บิลนี้ได้โปรดลองอีกครั้ง");
    }
  };

  const saveBillToFirestore = async () => {
    setLoadingSaveButton(true);
    try {
      const userId = userDetail?.uid;

      // Data sources based on mode
      const sourceDataMenu = isEditMode ? dataMenu : JSON.parse(localStorage.getItem("dataMenu") || "[]");
      const sourcePhoneNumber = isEditMode ? phoneNumber : localStorage.getItem("phoneNumber");
      const sourceDataPeople = isEditMode ? dataPeople : JSON.parse(localStorage.getItem("dataPeople") || "[]");
      const sourceBillName = isEditMode ? billName : localStorage.getItem("billName");

      // Check required fields
      if (!userId) {
        throw new Error("User ID is missing");
      }

      if (!sourceDataMenu.length) {
        throw new Error(t("itemList.errorMessageAddList"));
      }

      if (!sourceDataPeople.length) {
        throw new Error(t("personList.errorMessageAddPerson"));
      }

      await upsertUser(userDetail);

      // Prepare the bill data
      const billData: any = {
        userId,
        dataMenu: sourceDataMenu,
        dataPeople: sourceDataPeople,
        timestamp: new Date(),
      };

      if (sourcePhoneNumber) {
        billData.phoneNumber = sourcePhoneNumber;
      }

      if (sourceBillName) {
        billData.billName = sourceBillName;
      }

      let billRefId;

      // If isEditMode is true, update the existing bill, else create a new one.
      const currentBillId = isEditMode ? billIdEdit : billId;

      if (isEditMode) {
        if (!currentBillId) {
          throw new Error("billId is missing in edit mode");
        }
        const billRef = doc(db, "bills", currentBillId);
        await updateDoc(billRef, billData);
        billRefId = billId; // Keeping the existing bill ID
      } else {
        const billRef = await addDoc(collection(db, "bills"), billData);
        billRefId = billRef.id;
        // Add the bill to the user's sub-collection
        await addDoc(collection(db, "users", userId, "userBills"), {
          billId: billRefId,
        });
      }

      message.success(t("successMessage.saveBillComplete"));
      if (window.location.pathname === "/") {
        window.location.href = `/my-bills`;
        localStorage.removeItem("dataMenu");
        localStorage.removeItem("dataPeople");
        localStorage.removeItem("phoneNumber");
        localStorage.removeItem("billName");
      }

      setLoadingSaveButton(false);
      return billRefId; // return the document ID
    } catch (error: any) {
      if (
        error.message === t("itemList.errorMessageAddList") ||
        error.message === t("personList.errorMessageAddPerson")
      ) {
        message.error(error.message);
        setLoadingSaveButton(false);
      } else {
        console.error("Error saving bill: ", error);
      }
    }
  };

  useEffect(() => {
    const storedBillName = localStorage.getItem("billName");
    if (storedBillName) {
      setBillName(storedBillName);
      setShowInputBillName(false);
    }
  }, []);

  const PromptPayQRCode = ({ phoneNumber, amount }: QRPromptPay) => {
    const promptpayData = promptpayQR(phoneNumber, { amount });
    return <QRCode value={promptpayData} />;
  };

  const [dataPeople, setDataPeople] = useState<Person[]>(() => {
    const localData = localStorage.getItem("dataPeople");
    return localData ? JSON.parse(localData) : [];
  });

  const [dataMenu, setDataMenu] = useState<Menu[]>(() => {
    const localData = localStorage.getItem("dataMenu");
    return localData ? JSON.parse(localData) : [];
  });

  useEffect(() => {
    const fetchBill = async () => {
      setFetchedBillData(true);

      let currentBillId = billId;
      if (isEditMode) {
        currentBillId = billIdEdit;
      }

      if (currentBillId) {
        const billRef = doc(db, "bills", currentBillId);
        const billSnapshot = await getDoc(billRef);
        if (billSnapshot.exists()) {
          const billData = billSnapshot.data();
          setEditByOther(billData?.editByOther);
          setDataPeople(billData?.dataPeople || []);
          setDataMenu(billData?.dataMenu || []);
          setPhoneNumber(billData?.phoneNumber || "");
          setBillName(billData?.billName || "");
          setShowQRCode(Boolean(billData?.phoneNumber));
          setFetchedBillData(false);
          if (isEditMode) {
            setHideElement(false);
            setHideAuthenMenu(false);
          }
        } else {
          setFetchedBillData(false);
          window.location.href = "/";
          message.error("ไม่พบบิลนี้");
        }
      } else {
        setFetchedBillData(false);
      }
    };
    fetchBill();
  }, [billId, billIdEdit, isEditMode]);

  useEffect(() => {
    if (!isEditMode && !window.location.pathname.includes("/view-bill")) {
      localStorage.setItem("dataPeople", JSON.stringify(dataPeople));
    }
  }, [dataPeople, isEditMode]);

  useEffect(() => {
    if (!isEditMode && !window.location.pathname.includes("/view-bill")) {
      localStorage.setItem("dataMenu", JSON.stringify(dataMenu));
    }
  }, [dataMenu, isEditMode]);

  useEffect(() => {
    const localPhoneNumber = localStorage.getItem("phoneNumber");
    if (localPhoneNumber) {
      setPhoneNumber(localPhoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("phoneNumber")]);

  const handleSelectAll = () => {
    if (selectedTags.length === dataPeople.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags(dataPeople.map((item) => item.peopleName));
    }
  };

  const showModal = (values: { listNameInput: string }) => {
    const { listNameInput } = values;
    setListNameInModal(listNameInput);
    formMenu.setFieldsValue({ item: listNameInput });
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    try {
      const values = await formMenu.validateFields();

      setIsModalOpen(false);
      setSelectedTags([]);
      setLocalPrice(0);
      if (isEditing && editingItem) {
        setDataMenu((prevDataSource) =>
          prevDataSource.map((item) =>
            item.key === editingItem.key
              ? { ...editingItem, listName: values.item, price: values.price, selectedPeople: selectedTags }
              : item
          )
        );
      } else {
        const newItem: Menu = {
          key: dataMenu.length + 1,
          listName: values.item,
          price: values.price,
          each: "0",
          selectedPeople: selectedTags,
        };
        setDataMenu((prevDataSource) => prevDataSource.concat(newItem));
      }
      setIsEditing(false);
      setEditingItem(null);
      formMenu.resetFields();
      formInputMenuName.resetFields();
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedTags([]);
    formInputMenuName.resetFields();
    formMenu.resetFields();
  };

  const generateColors = () => {
    const hue = Math.floor(Math.random() * 360);
    const backgroundColor = `hsl(${hue}, 100%, 90%)`;
    const textColor = `hsl(${hue}, 80%, 40%)`;
    return { textColor, backgroundColor };
  };

  const handleAddPeopleSubmit = (values: { peopleName: string }) => {
    const { textColor, backgroundColor } = generateColors();
    setLastAction("add");
    if (values.peopleName.trim() === "") {
      message.error("กรุณากรอกชื่อ");
      return;
    }
    setDataPeople(
      dataPeople.concat({
        peopleName: values.peopleName,
        price: "0",
        color: textColor,
        backgroundColor: backgroundColor,
        id: dataPeople.length + 1,
        paymentStatus: false,
      })
    );
    formAddPeople.resetFields();
  };

  const handleOkEditName = () => {
    formEditName
      .validateFields()
      .then((values) => {
        if (personToEdit) {
          const index = dataPeople.findIndex((p) => p.peopleName === personToEdit.peopleName);
          const oldName = dataPeople[index].peopleName;

          // Create a new object with the updated values
          const updatedPerson = {
            ...dataPeople[index], // copy all existing properties
            peopleName: values.name,
            paymentStatus: values.paymentStatus,
          };

          // Replace the person object in the array
          const updatedPeople = [...dataPeople];
          updatedPeople[index] = updatedPerson;

          setDataPeople(updatedPeople);

          // Update other data if needed...
          const updatedMenu = dataMenu.map((menu) => ({
            ...menu,
            selectedPeople: menu.selectedPeople.map((name) => (name === oldName ? values.name : name)),
          }));

          setDataMenu(updatedMenu);
        }
        setIsModalEditOpen(false);
        setPersonToEdit(null);
        formEditName.resetFields();
      })
      .catch(() => {
        console.log("error");
      });
  };

  const handleCancelEditName = () => {
    setIsModalEditOpen(false);
    setPersonToEdit(null);
  };

  const columnsList = React.useMemo(() => {
    const handleDeleteMenu = (menuToDelete: Menu) => {
      setLastAction("delete");
      const updatedDataMenu = dataMenu.filter((menu) => menu.key !== menuToDelete.key);
      updatedDataMenu.forEach((item, index) => {
        item.key = index + 1;
      });
      setDataMenu(updatedDataMenu);
    };

    const initializeEditModal = (record: Menu) => {
      const priceNum = parseFloat(record.price);
      const editItem = {
        price: priceNum,
        negative: priceNum < 0,
      };
      setListNameInModal(record.listName);
      formMenu.setFieldsValue(editItem);
      setSelectedTags(record.selectedPeople);
      setLocalPrice(parseFloat(record.price));
      setIsModalOpen(true);
    };

    const baseColumns = [
      {
        title: `${t("itemList.tableColumnItemList")}`,
        dataIndex: "listName",
        key: "listName",
        width: "auto",
        align: "left",
        render: (text: string, record: Menu) => (
          <>
            <div>{text}</div>
            <div>
              {record.selectedPeople.map((item) => (
                <Tag
                  onClick={() => {
                    setSelectedPerson(dataPeople.find((person) => person.peopleName === item) || null);
                    setViewOrderModalOpen(true);
                  }}
                  style={{ marginBottom: 4, cursor: "pointer" }}
                  color={dataPeople.find((person) => person.peopleName === item)?.backgroundColor}
                  key={item}
                >
                  <span
                    style={{
                      color: dataPeople.find((person) => person.peopleName === item)?.color,
                    }}
                  >
                    {item}
                  </span>
                </Tag>
              ))}
            </div>
          </>
        ),
      },
      {
        title: `${t("itemList.tableColumnPrice")}`,
        dataIndex: "price",
        key: "Price",
        width: "auto",
        align: "left",

        render: (text: string) => {
          return parseInt(text).toLocaleString();
        },
      },
      {
        title: `${t("itemList.tableColumnPerPerson")}`,
        dataIndex: "each",
        key: "each",
        width: 70,
        align: "left",

        render: (text: string, record: Menu) => {
          const divisor = record.selectedPeople.length;
          const eachPrice = divisor !== 0 ? Math.ceil(parseInt(record.price) / divisor) : 0;
          return eachPrice.toLocaleString();
        },
      },
    ];

    if (!hideElement && !billId) {
      baseColumns.push({
        title: "Action",
        key: "action",
        dataIndex: "action",
        align: "center",
        width: "50px",
        render: (text: string, record: Menu) => (
          <React.Fragment>
            <div style={{ minWidth: 70 }}>
              <Button
                style={{
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  marginRight: 6,
                }}
                icon={<EditOutlined />}
                onClick={() => {
                  setEditingItem(record);
                  setIsEditing(true);
                  initializeEditModal(record);
                  setListNameInModal(record.listName);
                  setIsModalOpen(true);
                }}
              />
              <Popconfirm
                title={t("itemList.tableRowConfirmtoDelete", { listName: record.listName })}
                onConfirm={() => handleDeleteMenu(record)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="link"
                  icon={<DeleteOutlined />}
                  style={{ color: "#000000" }}
                  onClick={(event) => event.stopPropagation()}
                />
              </Popconfirm>
            </div>
          </React.Fragment>
        ),
      });
    }

    return baseColumns;
  }, [hideElement, billId, dataMenu, formMenu, dataPeople, t]);

  const handleRemoveItem = (menuToRemove: Menu) => {
    const updatedDataMenu = dataMenu.map((menu) => {
      if (menu.key === menuToRemove.key) {
        const updatedSelectedPeople = menu.selectedPeople.filter((name) => name !== selectedPerson?.peopleName);
        return { ...menu, selectedPeople: updatedSelectedPeople };
      }
      return menu;
    });
    setDataMenu(updatedDataMenu);
  };

  const tabListContent = (
    <div className="tab-list-content">
      <Table
        footer={() =>
          dataMenu.length > 0 && !window.location.pathname.includes("/view-bill") ? (
            <div style={{ textAlign: "center" }}>
              <UploadBill
                userDetail={userDetail}
                setDataMenu={setDataMenu as any}
                setBillName={setBillName as any}
                moreBill={true}
                setIsModalLoginOpen={setIsModalLoginOpen}
              />
            </div>
          ) : null
        }
        locale={{
          emptyText: (
            <UploadBill
              userDetail={userDetail}
              setDataMenu={setDataMenu as any}
              setBillName={setBillName as any}
              moreBill={false}
              setIsModalLoginOpen={setIsModalLoginOpen}
            />
          ),
        }}
        style={{
          minHeight: "200px",
        }}
        size="small"
        pagination={false}
        dataSource={dataMenu}
        columns={columnsList as any}
        className="table-list"
      />
      {window.location.pathname.includes("/view-bill") ? null : (
        <React.Fragment>
          <div className="form-add-list">
            <Form
              form={formInputMenuName}
              onFinish={showModal}
              style={{ width: "100%", marginTop: 15, display: hideElement ? "none" : "block" }}
            >
              <Space.Compact style={{ width: "100%", display: "flex" }} size="middle">
                <Form.Item
                  name="listNameInput"
                  style={{ flex: 1 }}
                  rules={[
                    { required: true, message: `${t("itemList.textValidateAddList")}` },
                    {
                      message: t("itemList.textValidateAddListDuplicate"),
                      validator: (_, value) => {
                        const existingItemNames = dataMenu.map((menu) => menu.listName);
                        if (value && existingItemNames.includes(value.trim())) {
                          return Promise.reject(t("itemList.textValidateAddListDuplicate"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <AutoComplete
                    open={openDropdownSuggestion}
                    onSearch={handleSearch}
                    options={filteredSuggestions.map((suggestion) => ({ value: suggestion }))}
                    onSelect={() => setOpenDropdownSuggestion(false)}
                  >
                    <Input placeholder={`${t("itemList.placeHolderAddList")}`} maxLength={25} />
                  </AutoComplete>
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<UnorderedListOutlined />}
                    onClick={() => {
                      setOpenDropdownSuggestion(false);
                    }}
                  >
                    {t("itemList.buttonAddList")}
                  </Button>
                </Form.Item>
              </Space.Compact>
            </Form>
          </div>
          <div
            className="clear-list"
            style={{
              display: hideElement ? "none" : "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              style={{ width: "40px" }}
              icon={<CalculatorOutlined />}
              onClick={() => {
                setModalCalculatorOpen(true);
              }}
            />
            <Button
              danger
              style={{ textAlign: "center" }}
              onClick={() => {
                setIsShowModalConfirmDelete(true);
                setTypeofDelete("list");
              }}
            >
              {t("itemList.buttonDeleteAllList")}
            </Button>
            <div style={{ width: "40px" }}></div>
          </div>
        </React.Fragment>
      )}
    </div>
  );

  const columnsPeople = React.useMemo(() => {
    const handleDeletePerson = (personToDelete: Person) => {
      const updatedDataPeople = dataPeople.filter((person) => person.peopleName !== personToDelete.peopleName);

      const updatedDataMenu = dataMenu.map((item) => {
        const updatedSelectedPeople = item.selectedPeople.filter((person) => person !== personToDelete.peopleName);

        const eachPrice = updatedSelectedPeople.length > 0 ? parseInt(item.price) / updatedSelectedPeople.length : 0;

        return {
          ...item,
          selectedPeople: updatedSelectedPeople,
          each: eachPrice.toString(),
        };
      });

      setDataPeople(updatedDataPeople);
      setDataMenu(updatedDataMenu);
    };

    const baseColumns = [
      {
        title: t("personList.tableColumnPersonList"),
        dataIndex: "peopleName",
        key: "peopleName",
        align: "left",
        width: "auto",
        render: (text: string, record: Person) => (
          <Tag
            color={record.backgroundColor}
            key={record.peopleName}
            style={{ padding: "4px", cursor: "pointer" }}
            onClick={() => {
              setSelectedPerson(record);
              setViewOrderModalOpen(true);
            }}
          >
            <span style={{ color: record.color, fontSize: "14px" }}>{text}</span>
          </Tag>
        ),
      },
      {
        title: t("personList.tableColumnPay"),
        dataIndex: "price",
        key: "Price",
        align: "left",
        width: "auto",
        render: (_: string, record: Person) => {
          let totalPrice = 0;
          dataMenu.forEach((item) => {
            const divisor = item.selectedPeople.length;
            if (item.selectedPeople.includes(record.peopleName) && divisor !== 0) {
              const eachPrice = parseInt(item.price) / divisor;
              totalPrice += Math.ceil(eachPrice);
            }
          });
          return totalPrice.toLocaleString();
        },
      },
      {
        title: t("personList.tableColumnPatStatus"),
        dataIndex: "paymentStatus",
        key: "paymentStatus",
        align: "center",
        width: "120px",
        render: (paymentStatus: boolean) =>
          paymentStatus ? (
            <Tag color="green">
              <CheckOutlined />
              <span style={{ marginLeft: 4 }}>{t("personList.modalEditPersonName.optionPaymentStatusPaid")}</span>
            </Tag>
          ) : (
            <Tag color="red">{t("personList.modalEditPersonName.optionPaymentStatusNotPaid")}</Tag>
          ),
      },
    ];

    if (!hideElement && !billId) {
      baseColumns.push({
        title: "Action",
        key: "action",
        dataIndex: "action",
        align: "center",
        width: "135px",
        render: (_: string, record: Person) => (
          <div style={{ maxWidth: 500 }}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setPersonToEdit(record);
                setEditingName(record.peopleName);
                setIsModalEditOpen(true);
                formEditName.setFieldsValue({
                  name: record.peopleName,
                  paymentStatus: record.paymentStatus,
                });
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setSelectedPerson(record);
                setViewOrderModalOpen(true);
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
            <Popconfirm
              title={t("personList.tableConfirmDeleteText", { peopleName: record.peopleName })}
              onConfirm={() => handleDeletePerson(record)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<DeleteOutlined />} style={{ color: "#000000" }} />
            </Popconfirm>
          </div>
        ),
      });
    } else if (!hideElement && billId) {
      baseColumns.push({
        title: "รายการ",
        key: "action",
        dataIndex: "action",
        align: "center",
        width: "50px",
        render: (_: string, record: Person) => (
          <div style={{ maxWidth: 500 }}>
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                setSelectedPerson(record);
                setViewOrderModalOpen(true);
              }}
              style={{
                border: "none",
                boxShadow: "none",
                backgroundColor: "transparent",
              }}
            />
          </div>
        ),
      });
    }

    return baseColumns;
  }, [hideElement, billId, dataPeople, dataMenu, formEditName, t]);

  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else if (inputRef.current && lastAction === "add") {
      inputRef.current.input.focus();
    }
  }, [dataPeople.length, isInitialRender, lastAction]);

  const formAddPeopleLayout = (
    <Form
      form={formAddPeople}
      onFinish={handleAddPeopleSubmit}
      style={{ width: "100%", marginTop: 15, display: hideElement ? "none" : "block" }}
    >
      <Space.Compact style={{ width: "100%", display: "flex" }}>
        <Form.Item
          name="peopleName"
          style={{ flex: 1 }}
          rules={[
            {
              required: isModalOpen ? false : true,
              message: t("personList.textValidateAddPerson"),
            },
            {
              message: t("personList.textValidateAddPersonDuplicate"),
              validator: (_, value) => {
                if (value && dataPeople.some((person) => person.peopleName === value.trim())) {
                  return Promise.reject(t("personList.textValidateAddPersonDuplicate"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input placeholder={t("personList.placeHolderAddPerson")} maxLength={15} ref={inputRef} autoComplete="off" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<UsergroupAddOutlined />}>
            {t("personList.buttonAddPerson")}
          </Button>
        </Form.Item>
      </Space.Compact>
    </Form>
  );

  const tabPriceContent = (
    <div className="tab-price-content">
      <Table
        size="small"
        className="table-peoples"
        // bordered
        dataSource={dataPeople}
        columns={columnsPeople as any}
        pagination={false}
        rowKey={(record) => record.id}
      />
      {window.location.pathname.includes("/view-bill") ? null : (
        <React.Fragment>
          <div className="form-add-people">{formAddPeopleLayout}</div>
          <div
            className="clear-list"
            style={{
              display: hideElement ? "none" : "block",
              textAlign: "center",
            }}
          >
            <Button
              danger
              onClick={() => {
                setTypeofDelete("people");
                setIsShowModalConfirmDelete(true);
              }}
            >
              {t("personList.buttonDeleteAllPerson")}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );

  const tabItems = [
    {
      label: (
        <div>
          <UnorderedListOutlined />
          {t("itemList.textItemList")}
        </div>
      ),
      key: "1",
      children: tabListContent,
    },
    {
      label: (
        <div>
          <UserOutlined />
          {t("personList.textPersonList")}
        </div>
      ),
      key: "2",
      children: tabPriceContent,
    },
  ];

  const handleChange = (tag: string, checked: boolean) => {
    const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const downloadScreenshot = () => {
    setSpinning(true);
    setHideElement(true);
    setHideAuthenMenu(true);
    const excludeSection = document.getElementById("excludeSection");

    if (excludeSection) {
      excludeSection.classList.add("hide-for-capture");
    }

    setTimeout(() => {
      const element = document.querySelector(".bill-container");
      if (element) {
        (element as HTMLElement).style.filter = "blur(3px)";
        html2canvas(element as HTMLElement)
          .then((canvas) => {
            if (excludeSection) {
              excludeSection.classList.remove("hide-for-capture");
            }
            return canvas.toBlob((blob) => {
              if (blob) {
                const objUrl = URL.createObjectURL(blob);

                if (isMobile) {
                  // Display a button for mobile users to open the image in a new tab
                  const btn = document.createElement("button");
                  btn.className = "screenshot-btn";
                  btn.innerHTML = `<div>${t("billActionSection.downloadNewTabText")} ${
                    currentTab === "1"
                      ? t("billActionSection.downloadListImage")
                      : t("billActionSection.downloadPersonList")
                  }</div> <div class="warning-text-capture">${t("billActionSection.warningTextDownload")}</div>`;
                  btn.style.position = "fixed";
                  btn.style.top = "50%";
                  btn.style.left = "50%";
                  btn.style.transform = "translate(-50%, -50%)";
                  btn.style.zIndex = "1000";
                  btn.onclick = () => {
                    window.open(objUrl, "_blank");
                    document.body.removeChild(btn);
                    (element as HTMLElement).style.filter = "none";
                    const container = document.querySelector(".bill-container");
                    if (container) {
                      (container as HTMLElement).style.filter = "none";
                      container.classList.remove("blurred-container");
                    }
                  };
                  document.body.appendChild(btn);
                } else {
                  const link = document.createElement("a");
                  link.href = objUrl;
                  link.download = "screenshot.png";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(objUrl);
                }
              } else {
                console.error("Blob conversion failed!");
              }
            }, "image/png");
          })
          .then(() => {
            setHideElement(false);
            setHideAuthenMenu(false);
            message.success(t("successMessage.downloadBillComplete"));
            setSpinning(false); // Stop the spinner

            // Update the button's text
            const btn = document.querySelector(".screenshot-btn") as HTMLElement;
            if (btn) {
              btn.innerText = "Open Screenshot";
            }

            // Blur the container
            const container = document.querySelector(".bill-container");
            if (container) {
              if (isMobile) {
                container.classList.add("blurred-container");
              } else {
                (container as HTMLElement).style.filter = "none";
                container.classList.remove("blurred-container");
              }
            }
          });
      } else {
        console.error("Element with class 'bill-container' not found!");
        setHideElement(false);
        setHideAuthenMenu(false);
      }
    }, 1000);
  };

  const handlePriceChange = (e: any) => {
    const priceValue = parseFloat(e.target.value) || 0;
    setLocalPrice(priceValue);
    formMenu.setFieldsValue({ negative: false });
  };

  const eachPrice =
    selectedTags.length && localPrice ? Math.ceil(localPrice / selectedTags.length).toLocaleString() : "0";

  useEffect(() => {
    formMenu.setFieldsValue({ item: listNameInModal });
  }, [listNameInModal, formMenu]);

  function handleOpenInBrowser() {
    const currentURL = window.location.href; // get current URL
    const el = document.createElement("textarea");
    el.value = currentURL;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    message.success(t("successMessage.copyUrlComplete"));
  }

  const checkIsGoogleAuthenDisplayed = () => {
    if (hideAuthenMenu) {
      return true;
    } else {
      return false;
    }
  };

  const handleNegativeToggle = () => {
    const existingPrice = formMenu.getFieldValue("price");
    const localPrice = parseFloat(existingPrice) || 0;
    formMenu.setFieldsValue({ price: -localPrice, negative: localPrice >= 0 });
  };

  const handleRowClick = (record: Menu) => {
    const keyAsString = record.key.toString();
    if (expandedRowKeys.includes(keyAsString)) {
      setExpandedRowKeys((prevKeys) => prevKeys.filter((key) => key !== keyAsString));
    } else {
      setExpandedRowKeys((prevKeys) => [...prevKeys, keyAsString]);
    }
  };

  return editByOther ||
    isOwner ||
    isOwner === null ||
    window.location.pathname === "/" ||
    window.location.pathname.includes("/view-bill") ? (
    <Spin spinning={spinning || fetchedBillData}>
      <div
        className="bill-container"
        style={{
          border: `${hideElement ? "7px solid #c5c6d0" : "0"}`,
        }}
      >
        {hideElement && (
          <div className="cover-ribbon">
            <div className="cover-ribbon-inside">Verified</div>
          </div>
        )}
        <GoogleAuthen hideElement={checkIsGoogleAuthenDisplayed()} />
        {hideElement ? <p style={{ textAlign: "center", fontStyle: "italic" }}>checkbillbyjsor.com</p> : null}
        <div style={{ textAlign: "center" }} className="bill-name">
          <h1
            style={{
              cursor: window.location.pathname.includes("/view-bill") ? "default" : "pointer",
            }}
            onClick={() => {
              if (window.location.pathname.includes("/view-bill")) {
                return;
              }
              setShowInputBillName(true);
              setAutoFocusBillName(true);
            }}
          >
            {billName}
          </h1>
          {!showInputBillName && !billName && (
            <Button
              danger={errorNeedBillName ? true : false}
              icon={<UnorderedListOutlined />}
              onClick={() => {
                setShowInputBillName(true);
                setAutoFocusBillName(true);
              }}
            >
              {t("billDetailSection.addBillName")}
            </Button>
          )}
          {showInputBillName && (
            <div className="add-bill-name">
              <Form
                initialValues={{
                  billName: localStorage.getItem("billName") || "",
                }}
                onFinish={(values) => {
                  if (!isEditMode) {
                    localStorage.setItem("billName", values.billName);
                  }
                  setBillName(values.billName);
                  setShowInputBillName(false);
                }}
                style={{ width: "100%", marginTop: 15, display: hideElement ? "none" : "block" }}
              >
                <Space.Compact style={{ width: "100%", display: "flex" }}>
                  <Form.Item name="billName" style={{ flex: 1 }}>
                    <Input
                      placeholder={t("billDetailSection.placeHolderAddBillName")}
                      maxLength={45}
                      ref={inputRef}
                      autoComplete="off"
                      autoFocus={autoFocusBillName}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<UnorderedListOutlined />}>
                      {t("billDetailSection.btnAddBillName")}
                    </Button>
                  </Form.Item>
                </Space.Compact>
              </Form>
            </div>
          )}
        </div>
        {currentLanguage !== "en" && (
          <div className="qr-code-section" style={{ textAlign: "center" }}>
            {showQRCode ? (
              <div
                className="qr-code-container"
                style={{
                  cursor: window.location.pathname.includes("/view-bill") ? "default" : "pointer",
                }}
                onClick={() => {
                  if (window.location.pathname.includes("/view-bill")) {
                    return;
                  }
                  setShowQRCode(false);
                  setShowInput(true);
                  setAutoFocusQRCode(true);
                }}
              >
                <PromptPayQRCode key={phoneNumber} phoneNumber={phoneNumber} amount={0} />
                <div className="promtpay-detail-and-copy">
                  <div>{phoneNumber}</div>
                  {!isEditMode && window.location.pathname !== "/" && !hideElement ? (
                    <Button
                      icon={<CopyOutlined />}
                      onClick={async () => {
                        try {
                          await navigator.clipboard.writeText(phoneNumber);
                          message.success(t("successMessage.copyQRComplete"));
                        } catch (error) {
                          message.error("ไม่สามารถแชร์บิลนี้ได้โปรดลองอีกครั้ง");
                        }
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : showInput ? (
              <div>
                <Form
                  initialValues={{
                    phoneNumber: localStorage.getItem("phoneNumber") || "",
                  }}
                  onFinish={(values) => {
                    setShowQRCode(true);
                    if (!isEditMode) {
                      localStorage.setItem("phoneNumber", values.phoneNumber);
                    } else {
                      setPhoneNumber(values.phoneNumber);
                    }
                  }}
                  style={{ width: "100%", marginTop: 15, display: hideElement ? "none" : "block" }}
                >
                  <Space.Compact style={{ width: "100%", display: "flex" }}>
                    <Form.Item name="phoneNumber" style={{ flex: 1 }}>
                      <Input
                        autoFocus={autoFocusQRCode}
                        inputMode="numeric"
                        placeholder="ระบุหมายเลขพร้อมเพย์"
                        maxLength={15}
                        ref={inputRef}
                        autoComplete="off"
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" icon={<UnorderedListOutlined />}>
                        เพิ่ม QR CODE
                      </Button>
                    </Form.Item>
                  </Space.Compact>
                </Form>
              </div>
            ) : (
              <Button
                icon={<QrcodeOutlined />}
                onClick={() => {
                  if (window.location.pathname.includes("/view-bill")) {
                    message.warning(t("billDetailSection.warningMsgForAddQrcode"));
                  } else {
                    setShowInput(true);
                    setAutoFocusQRCode(true);
                  }
                }}
              >
                {t("billDetailSection.addQrCodeMessage")}
              </Button>
            )}
          </div>
        )}
        <Row className="bill-detail">
          <Col span={12}>
            <div className="detail-section" style={{ cursor: "pointer" }}>
              <h2
                onClick={() => {
                  setCurrentTab("2");
                }}
              >
                {t("billDetailSection.numberofPeople")}
              </h2>
              <h3
                onClick={() => {
                  setCurrentTab("2");
                }}
              >
                {dataPeople.length}
              </h3>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-section">
              <h2>{t("billDetailSection.totalprice")}</h2>
              <h3>{dataMenu.reduce((sum, item) => sum + parseInt(item.price), 0).toLocaleString()}</h3>
            </div>
          </Col>
        </Row>
        {!isInsideWebView && (
          <div style={{ textAlign: "center", margin: "15px 0" }} id="excludeSection" className="bill-action">
            <Button
              icon={<DownloadOutlined />}
              onClick={downloadScreenshot}
              style={{
                display:
                  currentTab === "1" && dataMenu.length >= 1
                    ? "inline-block"
                    : currentTab === "2" && dataPeople.length >= 1
                    ? "inline-block"
                    : "none",
              }}
            >
              {`${
                currentTab === "1"
                  ? t("billActionSection.downloadListImage")
                  : t("billActionSection.downloadPersonList")
              }`}
            </Button>
            {!billId && (
              <Button
                loading={loadingSaveButton}
                type="primary"
                icon={<SaveOutlined />}
                onClick={() => {
                  if (userDetail) {
                    const billNameStorage = localStorage.getItem("billName");
                    if (!billNameStorage && billName === "") {
                      setErrorNeedBillName(true);
                      message.error(t("billActionSection.errorNeedBillName"));
                      return;
                    } else {
                      saveBillToFirestore();
                    }
                  } else {
                    setIsModalLoginOpen(true);
                  }
                }}
              >
                {t("billActionSection.saveBill")}
              </Button>
            )}
            {window.location.pathname !== "/" && (
              <Button
                loading={LoadingShareButton}
                icon={<ShareAltOutlined />}
                onClick={async () => {
                  setLoadingShareButton(true);
                  if (billId || billIdEdit) {
                    const link = `${window.location.origin}/view-bill/${billId || billIdEdit}`;
                    await copyToClipboard(link);
                  } else {
                    setIsModalLoginOpen(true);
                  }
                  setLoadingShareButton(false);
                }}
              >
                {t("billActionSection.shareBill")}
              </Button>
            )}
            {window.location.pathname === "/" &&
              (dataPeople.length > 2 ||
                dataMenu.length > 2 ||
                localStorage.getItem("billName") ||
                localStorage.getItem("phoneNumber")) && (
                <Button
                  danger
                  loading={LoadingShareButton}
                  icon={<ClearOutlined />}
                  onClick={async () => {
                    setModalClearBillOpen(true);
                  }}
                >
                  {t("billActionSection.clearBill")}
                </Button>
              )}

            {billId && (
              <Button
                icon={<EditOutlined />}
                onClick={() => {
                  window.location.href = `/edit-bill/${billId}`;
                }}
              >
                {t("billActionSection.edit")}
              </Button>
            )}
          </div>
        )}

        <Tabs
          activeKey={currentTab}
          defaultActiveKey="1"
          centered
          // items={visibleTabItems}
          tabBarGutter={0}
          items={tabItems}
          className="custom-tabs"
          animated={{
            inkBar: true,
            tabPane: true,
          }}
          size="middle"
          onChange={(e) => {
            setCurrentTab(e);
          }}
        />
        {isInsideWebView && (
          <div className="text-browser-warning">
            <span>{t("warningOpenInWebView.warnningText")}</span>
          </div>
        )}
        <Modal className="modal-add-list" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          <Form name="basic" form={formMenu} initialValues={{ item: listNameInModal }} size="large">
            <Form.Item
              name="item"
              rules={[{ required: true, message: t("itemList.modalAddList.textValidateListName") }]}
              className="input-item-name"
            >
              <Input placeholder={t("itemList.modalAddList.placeholderListName")} />
            </Form.Item>
            <Form.Item
              label={t("itemList.modalAddList.labelPrice")}
              name="price"
              style={{ margin: "15px 0" }}
              rules={[
                { required: true, message: t("itemList.modalAddList.textValidatePrice") },
                { pattern: /^-?[1-9]\d*$/, message: t("itemList.modalAddList.textValidatePriceNumber") },
              ]}
            >
              <Input
                type="number"
                inputMode="numeric"
                placeholder={t("itemList.modalAddList.labelPrice")}
                autoFocus
                onChange={handlePriceChange}
              />
            </Form.Item>
            <Form.Item name="negative" valuePropName="checked">
              <Checkbox onChange={handleNegativeToggle}>{t("itemList.modalAddList.negativeNumber")}</Checkbox>
              <Tooltip title={t("itemList.modalAddList.negativeNumberExplanation")}>
                <InfoCircleOutlined />
              </Tooltip>
            </Form.Item>
          </Form>
          <p>
            {t("itemList.modalAddList.labelCalculatePerPerson", {
              count: selectedTags.length,
              price: eachPrice,
            })}
          </p>
          <Space size={[0, 20]} wrap>
            {dataPeople.map((item) => (
              <CheckableTag
                key={item.peopleName}
                checked={selectedTags.includes(item.peopleName)}
                onChange={(checked) => handleChange(item.peopleName, checked)}
                style={{
                  backgroundColor: selectedTags.includes(item.peopleName) ? item.backgroundColor : "#eeeeee",
                  color: selectedTags.includes(item.peopleName) ? item.color : "#666666",
                  fontSize: "14px",
                  padding: "4px 8px",
                  lineHeight: "24px",
                }}
              >
                {selectedTags.includes(item.peopleName) ? <CheckOutlined /> : <PlusOutlined />}
                <span style={{ marginLeft: 5 }}>{item.peopleName}</span>
              </CheckableTag>
            ))}
          </Space>
          <Button onClick={handleSelectAll} style={{ width: "100%", margin: "15px 0" }}>
            {selectedTags.length === dataPeople.length ? <CloseOutlined /> : <CheckOutlined />}
            {selectedTags.length === dataPeople.length
              ? t("itemList.modalAddList.buttonCancelSelectAll")
              : t("itemList.modalAddList.buttonSelectAll")}
          </Button>

          {formAddPeopleLayout}
        </Modal>
        <Modal
          centered
          className="modal-edit-name"
          title={
            <div
              style={{
                textAlign: "center",
              }}
            >
              <span>{t("personList.modalEditPersonName.modalTitle")}</span>
            </div>
          }
          open={isModalEditOpen}
          onOk={handleOkEditName}
          onCancel={handleCancelEditName}
        >
          <Form onFinish={handleOkEditName} form={formEditName}>
            <Form.Item
              name="name"
              label={t("personList.modalEditPersonName.labelPersonName")}
              rules={[
                { required: true, message: t("personList.modalEditPersonName.textValidatePersonName") },
                {
                  message: t("personList.modalEditPersonName.textValidatePersonNameDuplicate"),
                  validator: (_, value) => {
                    if (value) {
                      const otherPeople = dataPeople.filter((person) => person !== personToEdit);
                      if (otherPeople.some((person) => person.peopleName === value.trim())) {
                        return Promise.reject(t("personList.modalEditPersonName.textValidatePersonNameDuplicate"));
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input value={editingName} onChange={(e) => setEditingName(e.target.value)} autoFocus />
            </Form.Item>
            <Form.Item
              label={t("personList.modalEditPersonName.labelPaymentStatus")}
              name="paymentStatus"
              rules={[{ required: true, message: "กรุณาเลือกสถานะการจ่ายเงิน" }]}
            >
              <Radio.Group>
                <Radio.Button value={true}>{t("personList.modalEditPersonName.optionPaymentStatusPaid")}</Radio.Button>
                <Radio.Button value={false}>
                  {t("personList.modalEditPersonName.optionPaymentStatusNotPaid")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          centered
          open={isShowModalConfirmDelete}
          title={
            typeofDelete === "list"
              ? t("itemList.textConfirmDeleteAllList")
              : t("personList.textConfirmDeleteAllPerson")
          }
          onCancel={() => setIsShowModalConfirmDelete(false)}
          onOk={() => {
            setIsShowModalConfirmDelete(false);

            if (typeofDelete === "list") {
              setDataMenu([]);
              localStorage.removeItem("dataMenu");
            } else {
              // Remove all people
              setDataPeople([]);
              localStorage.removeItem("dataPeople");

              // Remove all people references from menu items
              const updatedMenu = dataMenu.map((menuItem) => ({
                ...menuItem,
                selectedPeople: [],
              }));

              setDataMenu(updatedMenu);
              localStorage.setItem("dataMenu", JSON.stringify(updatedMenu)); // Save the updated data to localStorage
            }
          }}
        />
        <Modal
          title={
            <div style={{ textAlign: "center", padding: "15px 0" }}>
              {t("personList.modalWhatPeopleOrder.title", {
                personName: selectedPerson?.peopleName,
              })}
            </div>
          }
          open={isViewOrderModalOpen}
          onCancel={() => {
            setExpandedRowKeys([]);
            setViewOrderModalOpen(false);
          }}
          footer={null}
          className="modal-what-people-order"
          style={{ top: 20 }}
        >
          <Table
            expandable={{
              expandedRowKeys: expandedRowKeys,
              onExpandedRowsChange: (keys) => setExpandedRowKeys(Array.from(keys).map(String)),
            }}
            size="small"
            pagination={false}
            dataSource={dataMenu.filter((menu) => menu.selectedPeople.includes(selectedPerson?.peopleName ?? ""))}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record),
              };
            }}
          >
            <Table.Column
              title={t("personList.modalWhatPeopleOrder.tableColumnItemName")}
              width={"60%"}
              dataIndex="listName"
              key="listName"
              render={(text, record: Menu) => {
                const peopleNames = record.selectedPeople.map((personName) => {
                  const person = dataPeople.find((p) => p.peopleName === personName);
                  if (person) {
                    return (
                      <div
                        key={person.id}
                        style={{
                          display: "inline-block",
                          backgroundColor: person.backgroundColor,
                          color: person.color,
                          margin: "2px",
                          padding: "0 5px",
                          borderRadius: "5px",
                        }}
                      >
                        {person.peopleName}
                      </div>
                    );
                  }
                  return null;
                });

                const isRowExpanded = expandedRowKeys.includes(record.key.toString());

                return (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <span>{text}</span>
                      {isRowExpanded ? <RightOutlined /> : <DownOutlined />}
                    </div>
                    {isRowExpanded && <div style={{ fontSize: 14 }}>{peopleNames}</div>}
                  </div>
                );
              }}
            />
            <Table.Column
              title={t("personList.modalWhatPeopleOrder.tableColumnPrice")}
              dataIndex="price"
              key="price"
              render={(price) => new Intl.NumberFormat("th-TH").format(price)}
            />
            <Table.Column
              title={t("personList.modalWhatPeopleOrder.tableColumnPerPerson")}
              dataIndex="each"
              key="each"
              render={(text, record: Menu) => {
                const pricePerPerson = Math.ceil(Number(record.price) / Number(record.selectedPeople.length));
                return pricePerPerson.toLocaleString("en-US");
              }}
            />
            {!hideElement && !billId ? (
              <Table.Column
                title={t("personList.modalWhatPeopleOrder.delete")}
                key="action"
                align="center"
                render={(text, record: Menu) => (
                  <DeleteOutlined
                    style={{ color: "red", cursor: "pointer" }}
                    onClick={() => handleRemoveItem(record)}
                  />
                )}
              />
            ) : null}
          </Table>
        </Modal>
        <Modal
          centered
          onCancel={() => {
            setIsModalLoginOpen(false);
          }}
          className="modal-login"
          okButtonProps={{ style: { display: "none" } }}
          open={isModalLoginOpen}
          title={<div style={{ textAlign: "center" }}>{t("signInWarningMessage")}</div>}
        >
          <div className="wrap-signin-google">
            <Button
              className="google-signin-btn"
              onClick={loginWithGoogle}
              icon={<img src={googleSignInIcon} alt="google-signin" />}
            >
              {t("signInWithGoogle")}
            </Button>
          </div>
        </Modal>
        <Modal
          centered
          title={t("modalWarningOpenInWebview.title")}
          open={modalWarningWebView}
          onCancel={() => {
            setModalWarningWebView(false);
          }}
          maskClosable={false}
          closable={false}
          footer={[
            <Button key="submit" type="primary" onClick={handleOpenInBrowser}>
              {t("modalWarningOpenInWebview.buttonCopyLink")}
            </Button>,
          ]}
        >
          <p>{t("modalWarningOpenInWebview.text")}</p>
        </Modal>
        <Modal
          okButtonProps={{ danger: true }}
          centered
          title={t("billActionSection.clearBillConfirm")}
          open={modalClearBillOpen}
          onOk={() => {
            setShowQRCode(false);
            setShowInput(false);
            setShowInputBillName(false);
            setBillName("");
            setModalClearBillOpen(false);
            localStorage.removeItem("billName");
            localStorage.removeItem("phoneNumber");
            localStorage.removeItem("dataPeople");
            localStorage.removeItem("dataMenu");
            setDataPeople([]);
            setDataMenu([]);
          }}
          onCancel={() => {
            setModalClearBillOpen(false);
          }}
        />
        <Modal
          title="Calculator"
          open={modalCalculatorOpen}
          onCancel={() => setModalCalculatorOpen(false)}
          footer={null}
          centered
        >
          <Calculator />
        </Modal>
        <p
          style={{
            marginBottom: 0,
            fontStyle: "italic",
            textAlign: "center",
            fontSize: 13,
            padding: "0 15px",
            color: "rgba(0, 0, 0, 0.42)",
          }}
        >
          ** {t("warningTextforFraction")}
          <div style={{ marginTop: 12 }}>
            Powered by JSOR, version 1.4.0 <br /> Last updated: 22th January 2024
          </div>
        </p>
      </div>
    </Spin>
  ) : (
    <div className="bill-container" style={{ border: `${hideElement ? "3px solid #ccc" : "0"}` }}>
      <GoogleAuthen hideElement={checkIsGoogleAuthenDisplayed()} />
      <div className="permission-section">
        <h3>{t("billActionSection.editNotOwner")}</h3>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
        >
          {t("billActionSection.editBacktoHome")}
        </Button>
      </div>
    </div>
  );
}
